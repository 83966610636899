export default class Password {
  constructor(element) {
    this.element = element;
    this.input = document.getElementById(this.element.dataset.password);
    this.isVisible = this.input.getAttribute('type') !== 'password';

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.toggle();
  }

  toggle() {
    this.element.setAttribute('aria-checked', this.isVisible ? 'false' : 'true');
    this.input.setAttribute('type', this.isVisible ? 'password' : 'text');
    this.isVisible = !this.isVisible;
  }

  mount() {
    this.element.addEventListener('click', this.handleClick);
  }

  unmount() {
    this.element.removeEventListener('click', this.handleClick);
  }
}
