import 'mdn-polyfills/NodeList.prototype.forEach';
import Slider from './Slider';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('[data-slider]');
  if (!sliders.length) {
    return;
  }
  sliders.forEach((element) => {
    const slider = new Slider(element);

    if (slider.slides.length > 1) {
      slider.mount();
    }
  });
});
