import 'mdn-polyfills/NodeList.prototype.forEach';
import Dropdown from './Dropdown';

document.addEventListener('DOMContentLoaded', () => {
  const dropdowns = document.querySelectorAll('[data-dropdown]');
  if (!dropdowns.length) {
    return;
  }
  dropdowns.forEach((element) => {
    const dropdown = new Dropdown(element);
    dropdown.mount();
  });
});
