import 'mdn-polyfills/NodeList.prototype.forEach';
import UserList from './UserList';

document.addEventListener('DOMContentLoaded', () => {
  const userlists = document.querySelectorAll('[data-userlist]');
  if (!userlists.length) {
    return;
  }
  userlists.forEach((element) => {
    const userlist = new UserList(element);
    userlist.mount();
  });
});
