import 'mdn-polyfills/NodeList.prototype.forEach';

export default class UserList {
  constructor(element) {
    this.element = element;
    this.items = this.element.querySelectorAll('[data-userlist-item]');
    this.toggler = this.element.querySelector('[data-userlist-toggler]');

    this.handleTogglerClick = this.handleTogglerClick.bind(this);
  }

  handleTogglerClick(e) {
    e.preventDefault();
    this.toggle();
  }

  toggle() {
    this.items.forEach((item) => {
      item.removeAttribute('hidden');
    });

    this.toggler.removeEventListener('click', this.handleTogglerClick);
    this.toggler.remove();
    this.toggler = null;
  }

  mount() {
    if (this.toggler) {
      this.toggler.addEventListener('click', this.handleTogglerClick);
    }
  }

  unmount() {
    if (this.toggler) {
      this.toggler.removeEventListener('click', this.handleTogglerClick);
    }
  }
}
