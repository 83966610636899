import 'mdn-polyfills/NodeList.prototype.forEach';
import Password from './Password';

document.addEventListener('DOMContentLoaded', () => {
  const passwords = document.querySelectorAll('[data-password]');
  if (!passwords.length) {
    return;
  }
  passwords.forEach((element) => {
    const password = new Password(element);
    password.mount();
  });
});
