import A11yDialog from 'a11y-dialog';

export default class Popin {
  constructor(element) {
    this.element = element;
    this.delay = null;
    this.dialog = null;
    this.trigger = null;
    this.show = this.element.getAttribute('aria-hidden') === 'false';
  }

  mount() {
    this.dialog = new A11yDialog(this.element);

    this.dialog.on('show', (element, event) => {
      if (event) {
        this.trigger = event.target;
      }
    });

    if (this.show) {
      this.dialog.show();
    }
  }

  unmount() {
    this.dialog.destroy();

    this.dialog = null;
    this.trigger = null;
  }
}
