import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Number.isNaN';
import Swiper, { Navigation } from 'swiper';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.swiperElement = this.element.querySelector('[data-slider-swiper]');
    this.slides = this.element.querySelectorAll('[data-slider-slide]');
    this.prev = this.element.querySelector('[data-slider-control="prev"]');
    this.next = this.element.querySelector('[data-slider-control="next"]');
    this.settings = {
      loop: false,
      freeMode: true,
      slidesPerGroup: this.calculateSlidesPerGroup(),
      slidesPerView: 'auto',
      navigation: { nextEl: this.next, prevEl: this.prev },
      on: {
        init: (swiper) => {
          if (this.prev && this.next) {
            this.updateNavigation(swiper);
          }
        },
        slideChange: (swiper) => {
          if (this.prev && this.next) {
            this.updateNavigation(swiper);
          }
        },
      },
    };
    this.swiper = null;
  }

  calculateSlidesPerGroup() {
    const totalWidth = this.element.offsetWidth;
    const slideWidth = this.slides[0].offsetWidth;
    return Math.floor(totalWidth / slideWidth);
  }

  updateNavigation(swiper) {
    this.prev.removeAttribute('disabled');
    this.next.removeAttribute('disabled');

    if (swiper.isBeginning) {
      this.prev.setAttribute('disabled', '');
    }
    if (swiper.isEnd) {
      this.next.setAttribute('disabled', '');
    }
  }

  mount() {
    Swiper.use([Navigation]);

    this.swiper = new Swiper(this.swiperElement, this.settings);

    if (this.prev) {
      this.prev.removeAttribute('hidden');
    }
    if (this.next) {
      this.next.removeAttribute('hidden');
    }

    if (this.prev && this.next) {
      this.swiper.on('reachBeginning', () => {
        this.prev.setAttribute('disabled', '');
      });

      this.swiper.on('reachEnd', () => {
        this.next.setAttribute('disabled', '');
      });
    }
  }

  unmount() {
    if (this.prev) {
      this.prev.setAttribute('hidden', '');
    }
    if (this.next) {
      this.next.setAttribute('hidden', '');
    }

    this.destroy(this.swiper, true);
  }
}
